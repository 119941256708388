import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView column center alignCenter>
            <CFImage
              src={mobileHeroText}
              w="80vw"
              alt="About"
              mt="40px"
              mb="20px"
            />
            <CFView column justifyBetween alignCenter ph="10px">
              <CFView mb="5px" pulsate>
                <OrderPickupButton />
              </CFView>
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="700px"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
        >
          <Header />
          <CFView
            h="100vh"
            maxHeight="690px"
            column
            justifyCenter
            alignStart
            ml="5%"
          >
            <CFImage
              src={heroText}
              w="45%"
              minWidth="600px"
              alt="hero text"
              mb="35px"
            />
            <CFView mb="45px">
              <CFView mb="15px" pulsate>
                <OrderPickupButton />
              </CFView>
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
