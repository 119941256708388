import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          pv="5px"
          bg="rgba(0,0,0,.8)"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFView column center>
            <CFImage
              h="55px"
              src={logo}
              alt="JL Sushi & Kitchen Logo"
              mt="5px"
            />
            <CFImage
              h="15px"
              mv="5px"
              src={logoTitle}
              alt="JL Sushi & Kitchen Logo Title"
            />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="70px"
          bg="transparent"
          zIndex={98}
          bg="rgba(0,0,0,.8)"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="140px"
                mt="5px"
                src={logo}
                alt="JL Sushi & Kitchen Logo"
                zIndex={98}
              />
              <CFImage
                h="40px"
                mt="18px"
                ml="15px"
                src={logoTitle}
                alt="JL Sushi & Kitchen Logo Title"
              />
              {/* <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="15px" zIndex={99}>
                  <LocationSelect />
                </CFView>
              </CFSelect> */}
            </CFView>
            {/* <CFView hover mt="12px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="JL Sushi & Kitchen View Menu Button"
                />
              </CFLink>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
